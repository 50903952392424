<template>
  <page-section
    v-loading="loading"
    class="RelationGroup"
    :page-tooltip="group && group.name"
    :page-title="$t('pages.relations.groups.group_view.sidebar_description')"
  >
    <template #above-title>
      <dashboard-filters-v2 class="ConnectGroupFilter m-b-l">
        <template #primary>
          <search-input />
        </template>

        <template #secondary>
          <dashboard-filters-v2-item>
            <filter-category-picker />
          </dashboard-filters-v2-item>

          <dashboard-filters-v2-item>
            <filter-country-picker />
          </dashboard-filters-v2-item>

          <dashboard-filters-v2-item>
            <filter-sort-by />
          </dashboard-filters-v2-item>

          <div class="DashboardFiltersV2-ButtonsContainer">
            <dashboard-filters-v2-item>
              <router-link
                :to="{ name:'connect.groups.edit', params: { groupId } }"
                class="button is-dark m-l-m"
                data-testid="EditGroupButton"
              >
                {{ $t('components.relation_group_item.edit_group') }}
              </router-link>
            </dashboard-filters-v2-item>
          </div>
        </template>
      </dashboard-filters-v2>
    </template>

    <template v-if="group">
      <div class="RelationGroupItemStats p-h-l">
        <div class="columns is-multiline is-variable-grid">
          <div class="column">
            <data-card see-through :title="$t('general.hypefactors_value')" size="medium">
              {{ abbrMoney(group.stats.data.hypefactors_value) }}
            </data-card>
          </div>

          <div class="column">
            <data-card see-through :title="$t('general.impressions')" size="medium">
              {{ abbr(group.stats.data.impressions) }}
            </data-card>
          </div>

          <div class="column">
            <data-card see-through :title="$t('components.relation_group_item.no_of_relations')" size="medium">
              {{ abbrInt(group.relations_count) }}
            </data-card>
          </div>
        </div>
      </div>
      <hr class="m-v-l">

      <load-more
        ref="groupsLoadMore"
        v-loading="loading"
        :endpoint="groupRelationsEndpoint"
        :placeholder-items="2"
        width="column is-6"
        placeholder="RelationGroupPlaceholder"
        @loading="loading = false"
      >
        <template
          slot="item"
          slot-scope="props"
        >
          <relation-card
            :relation="props.item"
          />
        </template>
      </load-more>
    </template>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

import FilterCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersCountryPicker'
import FilterCategoryPicker from '@/components/connect/_filters/CategoriesFilter'
import FilterSortBy from '@/components/connect/_filters/SortBy'
import SearchInput from '@/components/connect/_filters/SearchInput'
import RelationCard from '@/components/connect/relations/Card'

import FormatMoneyAbbrMixin from '@hypefactors/shared/js/mixins/FormatMoneyAbbrMixin'
import AbbrIntMixin from '@hypefactors/shared/js/mixins/AbbrIntMixin'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

export default {
  provide () {
    return {
      groupId: this.groupId
    }
  },

  components: {
    SearchInput,
    FilterCategoryPicker,
    FilterCountryPicker,
    FilterSortBy,
    RelationCard
  },

  mixins: [FormatMoneyAbbrMixin, AbbrMixin, AbbrIntMixin],

  props: {
    groupId: {
      type: [String, Number],
      required: true
    }
  },

  data: () => ({
    loading: true,
    group: null
  }),

  computed: {
    ...mapGetters(['getFilter']),

    groupRelationsEndpoint () {
      return buildUrlWithParams(`/connect/groups/${this.groupId}/relations`, {
        brand: this.activeBrandId,
        search: this.getFilter('searchQuery'),
        sort: this.getFilter('sortBy'),
        categories: this.getFilter('categories'),
        countries: this.getFilter('country')
      })
    }
  },

  async created () {
    await this.fetchGroup()
  },

  methods: {
    async fetchGroup () {
      const endpoint = buildUrlWithParams(`/connect/groups/${this.groupId}`, {
        include: ['stats']
      })

      await this.$api.get(endpoint)
        .then((response) => {
          this.group = response.data.data
        })
        .catch(() => {
          this.$notify.error('** The Group was not found!')

          this.$router.push({ name: 'connect.groups' })
        })
    },

    changeSortBy (selected) {
      this.sorting.selected = [selected]

      this.$refs.sortByPicker.isVisible = false // hack to set the sortByPicker isVisible property
    }
  }
}
</script>
